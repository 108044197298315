import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_ax22bz5alsodbfwxjyctrtdli4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45user_45page_45guard_45global from "/app/app/middleware/00_UserPageGuard.global.ts";
import redirect_45global from "/app/app/middleware/redirect.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_drizzle-orm@0.32.2_postgres@3.4.4__iored_ax22bz5alsodbfwxjyctrtdli4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45user_45page_45guard_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}